export const config = {
  /**
   * IMPORTANT: IF YOU CHANGE ANYTHING HERE, YOU MUST ALSO CHANGE THE SAME VALUES IN next.config.mjs!!!
   * */
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string,
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   * */
  useCdn: process.env.NODE_ENV === 'development',
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION as string,
};

export default config;
