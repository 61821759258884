import { HiArrowRight } from 'react-icons/hi2';
import { MenuFeatureType } from '../types';
import { Image } from './Image';
import NoPrefetchLink from './NoPrefetchLink';

interface CardProps {
  menuFeature: MenuFeatureType;
  isMenuOpen?: boolean;
}

function MenuCard({ menuFeature, isMenuOpen }: CardProps) {
  const { overline, title, description, image, link } = menuFeature;
  if (!title) return null;

  return (
    <article className="@container overflow-hidden rounded-sm">
      <div className="@lg:flex">
        {image?.asset && (
          <Image
            src={image}
            priority
            width={16000}
            height={9000}
            sizes="100vw"
            className="@lg:w-1/2 object-cover"
          />
        )}
        <div className="bg-background text-on-background px-l py-xl @5xl:p-20 @5xl:justify-center @5xl:gap-5 flex flex-col gap-3">
          <div>
            {overline && (
              <span className="text-tertiary-variant text-base font-semibold">{overline}</span>
            )}
            <h2 className="text-2xl font-semibold">{title}</h2>
          </div>

          <p className="line-clamp-3 text-lg">{description}</p>

          {link.href && link.linkText && (
            <NoPrefetchLink
              href={link.href}
              tabIndex={isMenuOpen ? 0 : -1}
              className="text-secondary @3xl:justify-normal @3xl:gap-4 mt-2 flex items-center justify-between font-bold"
            >
              {link.linkText}
              <HiArrowRight className="stroke-1" />
            </NoPrefetchLink>
          )}
        </div>
      </div>
    </article>
  );
}

export default MenuCard;
