import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { LinkType, LoginType, MenuFeatureType, NestedMenuType } from '../../../../types';
import useMediaQuery from '../../../../utils/useMediaQuery';
import MenuCard from '../../../MenuCard';
import Icon, { Icons } from '../../../icons/Icon';
import Logo from '../Logo';
import BurgerButton from './BurgerButton';
import LogIn from './LogIn';
import {
  headerItemVariant,
  menuContentVariant,
  menuListVariant,
  menuVariant,
} from './variants/variants';
import NoPrefetchLink from '../../../NoPrefetchLink';

interface MobileMenuProps {
  menu: { links: NestedMenuType[]; defaultFeature: MenuFeatureType };
  logins: LoginType[];
  header: { links: LinkType[] };
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function MobileMenu({ menu, header, logins, isMenuOpen, setIsMenuOpen }: MobileMenuProps) {
  const { links, defaultFeature } = menu;
  const [currentDepth, setCurrentDepth] = useState(0);
  const [breadcrumb, setBreadcrumb] = useState<(string | undefined)[]>([undefined, undefined]);
  const [previousLinks, setPreviousLinks] = useState<NestedMenuType[]>(links);
  const [selectedLinks, setSelectedLinks] = useState(links);
  const [activeFeature, setActiveFeature] = useState(defaultFeature);
  const [direction, setDirection] = useState<-1 | 1>(1);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const isNarrow = useMediaQuery('(max-width: 1023px)');

  const handleBurgerClick = () => {
    setIsMenuOpen((prev) => {
      if (isNarrow) {
        if (prev) {
          document.body.style.overflow = 'unset';
        } else {
          document.body.style.overflow = 'hidden';
        }
      }
      return !prev;
    });

    if (isLoginOpen) {
      setIsLoginOpen(false);
    }
    if (!isMenuOpen) {
      setCurrentDepth(0);
      setSelectedLinks(links);
      setActiveFeature(defaultFeature);
      setIsLoginOpen(false);
    }
  };

  const handleSubmenuClick = (linkItem: NestedMenuType, prevLinks: NestedMenuType[]) => {
    setSelectedLinks(linkItem.links as NestedMenuType[]);
    if (currentDepth === 0) {
      setPreviousLinks(links);
    } else {
      setPreviousLinks(prevLinks);
    }
    setBreadcrumb((prev) => {
      const path = linkItem.link?.linkText;
      if (currentDepth === 0) return [path, undefined];
      return [prev[0], path];
    });

    if (linkItem.menuFeature?.title) {
      setActiveFeature(linkItem.menuFeature);
    }
    setCurrentDepth((prev) => prev + 1);
    setDirection(1);
  };

  const handleBreadcrumbClick = (index: number) => {
    if (index === 1) {
      setCurrentDepth(1);
      setBreadcrumb((prev) => [prev[0], undefined]);
      setSelectedLinks(previousLinks);
      setPreviousLinks(links);
    }
    if (index === 0) {
      setCurrentDepth(0);
      setSelectedLinks(links);
      setPreviousLinks(links);
      setActiveFeature(defaultFeature);
      setBreadcrumb([undefined, undefined]);
    }
    setDirection(-1);
  };

  const handleBackButtonClick = () => {
    setCurrentDepth((prev) => {
      if (prev > 0) return prev - 1;
      return prev;
    });
    setBreadcrumb((prev) => {
      if (currentDepth > 1) return [prev[0], undefined];
      return [undefined, undefined];
    });
    if (currentDepth === 1) {
      setSelectedLinks(links);
      setActiveFeature(defaultFeature);
    } else {
      setSelectedLinks(previousLinks);
    }
    setDirection(-1);
  };

  return (
    <nav className="flex h-full w-full items-center justify-between px-6 sm:px-10">
      <AnimatePresence mode="popLayout" initial={false}>
        {isMenuOpen && currentDepth > 0 ? (
          <motion.div
            initial="exit"
            animate="enter"
            exit="exit"
            variants={headerItemVariant}
            className="text-background z-10 flex w-full max-w-[calc(100%-48px-12px)] items-center gap-2 pl-1 text-lg"
          >
            <motion.button
              type="button"
              initial="exit"
              animate="enter"
              exit="exit"
              variants={headerItemVariant}
              onClick={() => handleBackButtonClick()}
            >
              <FiChevronLeft className="h-6 w-6" />
              <span className="sr-only">Tilbake</span>
            </motion.button>

            {breadcrumb.map((part, index) => {
              if (!part) return null;
              return (
                <>
                  {currentDepth === 2 && index === 1 && <span>/</span>}
                  <motion.button
                    key={part}
                    type="button"
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={headerItemVariant}
                    className="text-on-primary overflow-hidden text-ellipsis whitespace-nowrap px-1 py-2 font-bold tracking-wide underline underline-offset-4"
                    onClick={() => handleBreadcrumbClick(index)}
                  >
                    {part}
                  </motion.button>
                </>
              );
            })}
          </motion.div>
        ) : (
          <motion.div
            key="logo"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={headerItemVariant}
            className="z-10"
          >
            <Logo isMenuOpen={isMenuOpen} />
          </motion.div>
        )}
      </AnimatePresence>

      <BurgerButton
        handleMenuClick={() => {
          handleBurgerClick();
        }}
        isMenuOpen={isMenuOpen}
      />

      <div className="bg-background/30 absolute inset-x-0 top-20 z-10 h-px" />

      <motion.div
        initial="closed"
        animate={isMenuOpen ? 'open' : 'closed'}
        variants={menuVariant}
        style={isMenuOpen ? {} : { pointerEvents: 'none' }}
        className="text-background bg-primary fixed inset-x-0 top-0 overflow-hidden text-lg"
        aria-hidden={!isMenuOpen}
      >
        <motion.div
          key={selectedLinks[0].link?.linkText || ''}
          variants={menuContentVariant}
          custom={direction}
          initial="enter"
          animate="visible"
          className="invisible-scrollbar relative mt-20 flex select-none flex-col gap-2 overflow-y-auto p-6 py-8 sm:p-10"
        >
          <motion.ul
            initial="initial"
            animate="animate"
            variants={menuListVariant}
            className="flex h-[calc(100dvh-80px-64px)] flex-col gap-3 font-bold "
          >
            {currentDepth === 0 && (
              <>
                <TopLinks
                  links={header.links}
                  isMenuOpen={isMenuOpen}
                  handleBurgerClick={handleBurgerClick}
                />
                <LogIn
                  isMenuOpen={isMenuOpen}
                  logins={logins}
                  setIsLoginOpen={setIsLoginOpen}
                  isLoginOpen={isLoginOpen}
                />
              </>
            )}

            <Links
              links={selectedLinks}
              handleBurgerClick={handleBurgerClick}
              handleSubmenuClick={handleSubmenuClick}
            />
            <div className="py-8" key="card">
              <MenuCard menuFeature={activeFeature} />
            </div>
          </motion.ul>
        </motion.div>
      </motion.div>
    </nav>
  );
}

function TopLinks({
  links,
  isMenuOpen,
  handleBurgerClick,
}: {
  links: LinkType[];
  isMenuOpen: boolean;
  handleBurgerClick: () => void;
}) {
  return (
    <>
      {links.map((link) => {
        return (
          <li key={link.href}>
            <NoPrefetchLink
              href={link.href}
              onClick={() => {
                handleBurgerClick();
              }}
              className="flex items-center justify-between py-1.5 "
              tabIndex={isMenuOpen ? 0 : -1}
            >
              <div className="flex items-center gap-4">
                {link.href.includes('forhandler') && (
                  <Icon icon={Icons.MAP_PIN} className="h-8 w-8 p-1" />
                )}
                {link.href.includes('kampanjer') && (
                  <Icon icon={Icons.PERCENTAGE} className="h-8 w-8 p-1" />
                )}
                {link.linkText}
              </div>
            </NoPrefetchLink>
          </li>
        );
      })}
    </>
  );
}

function Links({
  links,
  handleBurgerClick,
  handleSubmenuClick,
}: {
  links: NestedMenuType[];
  handleBurgerClick: () => void;
  handleSubmenuClick: (linkItem: NestedMenuType, prevLinks: NestedMenuType[]) => void;
}) {
  return (
    <>
      {links.map((linkItem) => {
        if (!linkItem.link) return null;

        if (linkItem.links) {
          return (
            <li key={linkItem.link.linkText}>
              <button
                type="button"
                onClick={() => handleSubmenuClick(linkItem, links)}
                className="border-background/25 flex w-full items-center justify-between border-b py-3 active:opacity-50"
              >
                {linkItem.link?.linkText}
                <FiChevronRight className="h-6 w-6" />
              </button>
            </li>
          );
        }

        return (
          <li key={linkItem.link.linkText}>
            <NoPrefetchLink
              href={linkItem.link?.href || '/'}
              className="border-background/25 flex w-full items-center justify-between border-b py-2 active:opacity-50"
              onClick={() => {
                handleBurgerClick();
              }}
            >
              {linkItem.link?.linkText}
            </NoPrefetchLink>
          </li>
        );
      })}
    </>
  );
}

export default MobileMenu;
