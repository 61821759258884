// utils/schemaHelper.ts
export function getSchemaWithDefaults(schemaData: any, pageContent: any) {
  const defaultSchemas = {
    articleMarkup: getArticleSchema(schemaData.articleFields, pageContent),
    faqMarkup: getFAQSchema(schemaData.faqFields, pageContent),
    jobPostingMarkup: getJobPostingSchema(schemaData.jobPostingFields, pageContent),
    eventMarkup: getEventSchema(schemaData.eventFields, pageContent),
    localBusinessMarkup: getLocalBusinessSchema(schemaData.localBusinessFields, pageContent),
    serviceMarkup: getServiceSchema(schemaData.serviceFields),
  } as { [key: string]: any };

  return defaultSchemas[schemaData.type] || {};
}

function getArticleSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    headline: schemaData?.headline || pageContent.title,
    image: schemaData?.image || pageContent.image,
    description: schemaData?.description || pageContent.description,
  };
}

function getFAQSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity:
      Array.isArray(schemaData?.questions) && schemaData.questions.length > 0
        ? schemaData.questions.map((q: any) => ({
            '@type': 'Question',
            name: q?.question || 'Default Question Name',
            acceptedAnswer: {
              '@type': 'Answer',
              text: q?.answer || 'Default Answer Text',
            },
          }))
        : [
            {
              '@type': 'Question',
              name: 'Default Question Name',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Default Answer Text',
              },
            },
          ],
  };
}

function getJobPostingSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: schemaData?.title || pageContent.jobPostingFields?.title || 'Default Title',
    description:
      schemaData?.description || pageContent.jobPostingFields?.description || 'Default Description',
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Møller Bil',
    },
    datePosted:
      schemaData?.datePosted || pageContent.jobPostingFields?.datePosted || 'Default Date',
    validThrough:
      schemaData?.validThrough ||
      pageContent.jobPostingFields?.validThrough ||
      'Default Valid Through',
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry:
          schemaData?.location?.addressCountry ||
          pageContent.jobPostingFields?.location?.addressCountry ||
          'Default Country',
        streetAddress:
          schemaData?.location?.streetAddress ||
          pageContent.jobPostingFields?.location?.streetAddress ||
          'Default Street',
        postalCode:
          schemaData?.location?.postalCode ||
          pageContent.jobPostingFields?.location?.postalCode ||
          'Default Postal Code',
        addressLocality:
          schemaData?.location?.addressLocality ||
          pageContent.jobPostingFields?.location?.addressLocality ||
          'Default Locality',
      },
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'NOK',
      value: {
        '@type': 'QuantitativeValue',
        value:
          schemaData?.baseSalary || pageContent.jobPostingFields?.baseSalary || 'Default Salary',
        unitText: 'YEAR',
      },
    },
    employmentType:
      schemaData?.employmentType ||
      pageContent.jobPostingFields?.employmentType ||
      'Default Employment Type',
  };
}

function getEventSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Event',
    name: schemaData?.name || pageContent.eventName,
    url: schemaData?.url || pageContent.eventUrl,
  };
}

function getLocalBusinessSchema(schemaData: any, pageContent: any) {
  const address = schemaData?.address || {};

  return {
    '@context': 'https://schema.org/',
    '@type': schemaData?.specificType || 'LocalBusiness',
    name: schemaData?.name || 'Default Name',
    url: schemaData?.id || 'https://mollerbil.no',
    image: schemaData?.image || 'Default Image URL',
    telephone: schemaData?.telephone || 'Default Telephone',
    priceRange: schemaData?.priceRange || 'Default Price Range',
    address: {
      '@type': 'PostalAddress',
      streetAddress: address?.streetAddress || 'Default Street',
      addressLocality: address?.addressLocality || 'Default Locality',
      postalCode: address?.postalCode || '0000',
      addressRegion: address?.addressRegion || 'Default Region',
      addressCountry: 'NO',
    },
  };
}

function getServiceSchema(schemaData: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Service',
    name: schemaData?.name || 'Default Service Name',
    description: schemaData?.description || 'Default Service Description',
    provider: {
      '@type': 'Organization',
      name: schemaData?.provider || 'Møller Bil',
    },
    serviceType: schemaData?.serviceType || 'Default Service Type',
    url: schemaData?.url || 'https://mollerbil.no',
  };
}

const websiteSchemaCode = {
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'Møller Bil',
  url: 'mollerbil.no',
  description:
    'Autorisert bilforhandler av Audi, Volkswagen, og Skoda med landsdekkende service- og verkstedtilbud.',
};

const organizationSchemaCode = {
  '@context': 'https://schema.org/',
  '@type': 'Organization',
  name: 'Møller Bil',
  url: 'https://mollerbil.no',
  logo: 'https://mollerbil.no/logo.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Frysjaveien 31B',
    addressLocality: 'Oslo',
    postalCode: '0884',
    addressCountry: 'NO',
  },
};

const breadcrumbSchemaCode = (name: string, url: string) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': url,
          name,
        },
      },
    ],
  };
};

const eventSchemaCode = (name: string, url: string) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Event',
    name,
    url,
  };
};

const localBusinessSchemaCode = (schemaData: any) => {
  const address = schemaData?.address || {};

  return {
    '@context': 'https://schema.org/',
    '@type': schemaData?.specificType || 'LocalBusiness',
    name: schemaData?.name || 'Default Name',
    url: schemaData?.id || 'https://mollerbil.no',
    image: schemaData?.image || 'Default Image URL',
    telephone: schemaData?.telephone || 'Default Telephone',
    priceRange: schemaData?.priceRange || 'Default Price Range',
    address: {
      '@type': 'PostalAddress',
      streetAddress: address?.streetAddress || 'Default Street',
      addressLocality: address?.addressLocality || 'Default Locality',
      postalCode: address?.postalCode || 'Default Postal Code',
      addressRegion: address?.addressRegion || 'Default Region',
      addressCountry: 'NO',
    },
  };
};

const serviceSchemaCode = (schemaData: any) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Service',
    name: schemaData?.name || 'Default Service Name',
    description: schemaData?.description || 'Default Service Description',
    provider: {
      '@type': 'Organization',
      name: schemaData?.provider || 'Møller Bil',
    },
    serviceType: schemaData?.serviceType || 'Default Service Type',
    url: schemaData?.url || 'https://mollerbil.no',
  };
};

export {
  websiteSchemaCode,
  organizationSchemaCode,
  breadcrumbSchemaCode,
  eventSchemaCode,
  localBusinessSchemaCode,
  serviceSchemaCode,
};

export function getSchemasWithDefaults(schemaArray: any[], pageContent: any) {
  return schemaArray.map((schemaData) => {
    const defaultSchemas = {
      articleMarkup: getArticleSchema(schemaData.articleFields, pageContent),
      faqMarkup: getFAQSchema(schemaData.faqFields, pageContent),
      jobPostingMarkup: getJobPostingSchema(schemaData.jobPostingFields, pageContent),
      eventMarkup: getEventSchema(schemaData.eventFields, pageContent),
      localBusinessMarkup: getLocalBusinessSchema(schemaData.localBusinessFields, pageContent),
      serviceMarkup: getServiceSchema(schemaData.serviceFields),
    } as { [key: string]: any };

    const schema = defaultSchemas[schemaData.type] || {};
    return schema;
  });
}
