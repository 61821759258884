import { FaFacebook, FaSnapchatGhost, FaTiktok, FaVimeo, FaYoutube } from 'react-icons/fa';
import { FiArrowRight, FiChevronRight, FiArrowUp } from 'react-icons/fi';
import { RiInstagramFill } from 'react-icons/ri';
import { twMerge } from 'tailwind-merge';
import Article from './Article';
import Burger from './Burger';
import MapPin from './MapPin';
import Percentage from './Percentage';
import Person from './Person';

export enum Icons {
  MAP_PIN = 'mapPin',
  PERSON = 'person',
  PERCENTAGE = 'percentage',
  BURGER = 'burger',
  ARTICLE = 'article',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  SNAPCHAT = 'snapchat',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  TIKTOK = 'tiktok',
  CHEVRON_RIGHT = 'chevron_right',
  ARROW_RIGHT = 'arrow_right',
  ARROW_UP = 'arrow_up',
}

function Icon({ icon = '', className = '' }: { icon: string; className?: string }) {
  switch (icon) {
    case Icons.BURGER:
      return <Burger className={twMerge(`h-5 w-5`, className)} />;
    case Icons.ARTICLE:
      return <Article className={twMerge(`h-5 w-5`, className)} />;
    case Icons.MAP_PIN:
      return <MapPin className={twMerge(`h-5 w-5`, className)} />;
    case Icons.PERSON:
      return <Person className={twMerge(`h-5 w-5`, className)} />;
    case Icons.PERCENTAGE:
      return <Percentage className={twMerge(`h-5 w-5`, className)} />;
    case Icons.FACEBOOK:
      return <FaFacebook className={twMerge(`h-5 w-5`, className)} />;
    case Icons.INSTAGRAM:
      return <RiInstagramFill className={twMerge(`h-5 w-5`, className)} />;
    case Icons.SNAPCHAT:
      return <FaSnapchatGhost className={twMerge(`h-5 w-5`, className)} />;
    case Icons.YOUTUBE:
      return <FaYoutube className={twMerge(`h-5 w-5`, className)} />;
    case Icons.VIMEO:
      return <FaVimeo className={twMerge(`h-5 w-5`, className)} />;
    case Icons.TIKTOK:
      return <FaTiktok className={twMerge(`h-5 w-5 ${className}`)} />;
    case Icons.CHEVRON_RIGHT:
      return <FiChevronRight className={twMerge(`h-5 w-5 ${className}`)} />;
    case Icons.ARROW_RIGHT:
      return <FiArrowRight className={twMerge(`h-5 w-5 ${className}`)} />;
    case Icons.ARROW_UP:
      return <FiArrowUp className={twMerge(`h-5 w-5 ${className}`)} />;
    default:
      return null;
  }
}

export default Icon;
