import Link from 'next/link';
import { HiArrowRight } from 'react-icons/hi2';
import { LinkType } from '../../../../types';
import NoPrefetchLink from '../../../NoPrefetchLink';

interface DescriptionLinksProps {
  description?: string;
  links: LinkType[];
}

function DescriptionLinks({ description, links }: DescriptionLinksProps) {
  return (
    <div className="basis-1/3">
      {description && <p>{description}</p>}
      {Array.isArray(links) && (
        <ul className="gap-xxs mt-4 flex flex-col sm:mt-6">
          {links.map((item) => {
            return (
              <li
                key={item._key}
                className="gap-s group relative flex max-w-max cursor-pointer items-center"
              >
                <NoPrefetchLink
                  href={item.href || '/'}
                  className="relative flex items-center justify-between gap-2 py-1 pr-2 text-base font-medium tracking-wide sm:text-lg"
                >
                  {item.linkText}
                  <HiArrowRight className="stroke-2 duration-200 group-hover:translate-x-2" />
                </NoPrefetchLink>
                <span className="absolute bottom-0 h-0.5 w-0 bg-gray-400 opacity-0 duration-200 group-hover:w-full group-hover:bg-white group-hover:opacity-100 group-active:w-0" />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default DescriptionLinks;
