import { LinkType, SocialMediaType } from '../../../../types';
import Logo from '../../../icons/LogoWordMark';
import SoMe from '../Parts/SoMe';
import NoPrefetchLink from '../../../NoPrefetchLink';

interface BottomProps {
  bottom: LinkType[];
  socialMedia: SocialMediaType;
}

function Bottom({ bottom, socialMedia }: BottomProps) {
  return (
    <section>
      <div className="my-6 flex flex-wrap justify-between gap-5">
        <Logo className="h-xxl min-w-[150px] sm:min-w-[180px]" />
        <SoMe socialMedia={socialMedia} />
      </div>

      <hr className="bg-background h-px w-full" />

      <div className="gap-s pt-xxs flex flex-col justify-between lg:flex-row-reverse lg:items-center ">
        <Links bottom={bottom} />
        <span className="text-background/80">&copy; {new Date().getFullYear()} Møller Bil</span>
      </div>
    </section>
  );
}

function Links({ bottom }: { bottom: LinkType[] }) {
  if (!Array.isArray(bottom)) {
    return null;
  }

  return (
    <ul className="gap-x-l max-lg:mt-xxs flex flex-wrap gap-y-1">
      {bottom.map((entry) => {
        return (
          <li key={entry._key} className="group flex items-center">
            <NoPrefetchLink
              href={entry.href || ''}
              className="gap-xxs py-xxs relative flex h-full items-center"
            >
              {entry.linkText}
              <span className="bg-background absolute bottom-0 left-0 h-px w-0 duration-300 group-hover:w-full group-active:w-0" />
            </NoPrefetchLink>
          </li>
        );
      })}
    </ul>
  );
}

export default Bottom;
